export default [
  {
    header: 'Others',
  },
  {
    title: 'homePage',
    route: 'dashboard-full-statistics',
    icon: 'HomeIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  // {
  //   title: 'Analitika',
  //   route: 'dashboard-analytics',
  //   icon: 'ActivityIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  // {
  //   title: 'Access Control',
  //   route: 'access-control',
  //   icon: 'ShieldIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'category',
    route: 'category',
    icon: 'InfoIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'advice',
    route: 'advice',
    icon: 'TwitchIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  // {
  //   title: 'advice-blog',
  //   route: 'advice-blog',
  //   icon: 'TwitchIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  {
    title: 'development',
    route: 'development',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'development-list',
    route: 'development-list',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'game',
    route: 'game',
    icon: 'SlackIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'game-blog',
    route: 'game-blog',
    icon: 'SlackIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  // {
  //   title: 'Menu Levels',
  //   icon: 'MenuIcon',
  //   children: [
  //     {
  //       title: 'Menu Level 2.1',
  //       route: null,
  //     },
  //     {
  //       title: 'Menu Level 2.2',
  //       children: [
  //         {
  //           title: 'Menu Level 3.1',
  //           route: null,
  //         },
  //         {
  //           title: 'Menu Level 3.2',
  //           route: null,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Disabled Menu',
  //   route: null,
  //   icon: 'EyeOffIcon',
  //   disabled: true,
  // },
  // {
  //   title: 'Raise Support',
  //   href: 'https://pixinvent.ticksy.com/',
  //   icon: 'LifeBuoyIcon',
  // },
  // {
  //   title: 'Documentation',
  //   href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation',
  //   icon: 'FileTextIcon',
  // },
]
